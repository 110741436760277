<template>
  <div class="content">
    <div class="subcontent">
      <h1>Basic Text Area</h1>
    </div>
    <div class="subcontent">
      <textarea class="box" name="text" v-model="value" @input="changeValue"></textarea>
    </div>
    <div class="subcontent">
      <button class="button button-clear" @click="clear()">CLEAR</button>
      <button class="button button-copy" @click="copy()">COPY</button>
    </div>
  </div>
</template>

<script>
import api from "./api/api";

export default {
  data() {
    return {
      value: ""
    };
  },
  async created() {
    this.value = (await api.getText()).data;
    // await this.autoUpdate();
  },
  methods: {
    async changeValue() {
      await api.updateText(this.value);
    },
    async clear() {
      this.value = "";
      await api.updateText(this.value);
    },
    copy() {
      this.$copyText(this.value);
    },
    async autoUpdate() {
      setInterval(async () => {
        this.value = (await api.getText()).data;
      }, 2000);
    }
  }
};
</script>

<style>
body {
  background-color: black;
  color: #cfcfcf;
  font-family: Tahoma, sans-serif;
  padding: 0 1em;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.subcontent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 100%;
  max-width: 800px;
  min-height: 300px;
  background-color: black;
  color: #cfcfcf;
  box-sizing: border-box;
  font-size: 15px;
}

.button {
  width: 200px;
  height: 50px;
  margin: 10px 10px;
  background: none;
  border: 1px solid rgb(83, 83, 83);
  color: white;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.25s ease;
}

.button:hover {
  cursor: pointer;
}

.button:focus {
  outline: 0;
}

.button-copy:hover {
  background-color: rgb(0, 99, 30);
}

.button-clear:hover {
  background-color: rgb(99, 0, 0);
}

textarea:focus {
  outline: 0;
}
</style>
